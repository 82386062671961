<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="onCloseClick.emit()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
